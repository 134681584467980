import React from "react";

import Layout from '../components/Layout';
import PostsList from '../components/PostsList';
import Head from '../components/Head';

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
        <PostsList />
    </Layout>
  );
};

export default IndexPage;
