import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledWrapper = styled.section`
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 0 2rem 0;

      &:last-child {
        margin: 0;
      }

      p {
        text-align: justify;
        line-height: 2rem;
        font-size: 18px;
      }

      a {
        text-shadow: none;
        transition: ${({ theme }) => theme.animations.linkHoverTransitionTime}s;
        &:hover {
          text-shadow: 2px 2px 0px rgb(0, 0, 0, 0.2);
        }
      }
    }
  }

  article h2 {
    margin-top: 30px;
  }

  h4,
  h6 {
    margin-bottom: 0.5rem;
  }

  .post-date {
    font-style: italic;
  }

  h6 {
    margin-top: 0;
  }
`

const PostsList = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { status: { eq: "publish" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "D MMM YYYY")
              status
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <StyledWrapper>
      <ol>
        {data.allMarkdownRemark.edges.map(edge => (
          <li key={edge.node.fields.slug}>
            <Link to={`/blog/${edge.node.fields.slug}`}>
              <h2>{edge.node.frontmatter.title}</h2>
            </Link>
            <span className="post-date">{edge.node.frontmatter.date}</span>
            <p>{edge.node.excerpt}</p>
          </li>
        ))}
      </ol>
    </StyledWrapper>
  )
}

export default PostsList
